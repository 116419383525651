import React from "react"

const Sponsors = () => {
  return (
    <>
      <section id="sponsors">
        <div className="container tc">
          <div className="s-100"></div>
          <div className="s-100"></div>
          <div className="m-40"></div>
          <div className="m-40"></div>
          <h3 className="c-marine">Приєднуйтесь до спільноти!</h3>
          <div className="s-40"></div>
          <p className="title w-60 ma c-white">Приєднуйтесь до нас як партнер або спонсор, та долучайтеся до нашої спільної ініціативи для покращення життя українців та підтримки наших ветеранів, та людей з підвищеними потребами.</p>
          <div className="s-80"></div>
          <a href="https://form.typeform.com/to/KnyTqmCK#business_type=partnership&source_id=our-website&button_id=become_partner" className="button mob-auto mx-3" target='blank'>Стати партнером</a>
          <a href="https://form.typeform.com/to/KnyTqmCK#business_type=sponsorship&source_id=our-website&button_id=become_sponsor" className="button theme mob-auto mx-3" target='blank'>Стати спонсором</a>
          <div className="s-100"></div>
          <div className="s-100"></div>
          <div className="m-40"></div>
          <div className="m-40"></div>
        </div>
      </section>
    </>
  )
}

export { Sponsors }
