import React from 'react'
import logotype from '../logo.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const NavPages = () => {

  return (
    <header className='w-100'>
      <div className='container'>
        <header className="flex jcsb aic h-10 py-5">
          <h1 id="logo">
            <a href='/' aria-label="IT Sprout - Головна"><LazyLoadImage src={logotype} width={140} height={46} alt="IT Sprout" /></a>
          </h1>
          <div className="flex jcfe aic mobile">
            <a href="/" className='mx-4 c-dark' aria-label="Головна">Головна</a>
          </div>
        </header>
        <div className="s-100"></div>
      </div>
    </header>
  )
}

export {NavPages}
