import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {Home} from './pages/Home'
import {Privacy} from './pages/Privacy'
import './styles/custom.sass'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="privacy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  )
}

export {App}
