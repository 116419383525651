import React from 'react'
import logotype from '../logo.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Footer = () => {
  const year = new Date()
  return (
    <footer id='contact' className='bg-white'>
      <div className='container'>
        <div className="s-40"></div>
        <div className="row">
          <div className="col-lg-8">
            <a href='#top' aria-label="IT Sprout - Головна"><LazyLoadImage src={logotype} width={48} height={46} alt="IT Sprout" /></a>
          </div>
          <div className="col-lg-4 tr">
            <p>65007, Україна, Одесса,<br />
              Старопортофранкивська 107/21<br />
              Телефон: <a href="tel: +380503240484">+380503240484</a>
            </p>
          </div>
        </div>
        <div className="s-40"></div>
        <div className="row">
          <div className="col-lg-8">
          <p>@{year.getFullYear()} - <a href='/'>IT Sprout</a>. Усі права захищено</p>
          </div>
          <div className="col-lg-4 tr">
            <a href='/privacy' aria-label="Політика конфіденційності">Політика конфіденційності</a>
          </div>
        </div>
        <div className="s-20"></div>
      </div>
    </footer>
  )
}

export {Footer}
