import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import pic001 from '../../assets/partners/pic001.webp'
import pic002 from '../../assets/partners/pic002.webp'
import pic003 from '../../assets/partners/pic003.webp'
import pic004 from '../../assets/partners/pic004.webp'
import pic005 from '../../assets/partners/pic005.webp'

const data = [
  {
    id: 1,
    link: 'https://www.siroccoenergy.com/',
    title: 'Sirocco Energy',
    label: 'Shape the Future of the Urban Wind',
    logo: pic001
  },
  {
    id: 2,
    link: 'https://wszop.edu.pl/',
    title: 'WSZOP',
    label: 'University of Occupational Safety Management in Katowice',
    logo: pic002
  },
  {
    id: 3,
    link: 'https://www.u-hertz.com/',
    title: 'U-Hertz',
    label: 'Engaging and innovative Alexa skills for businesses and individuals',
    logo: pic003
  },
  {
    id: 4,
    link: 'https://1point61.top/',
    title: 'Marketing 1.61',
    label: 'PERFORMANCE MARKETING FOR THE GROWTH OF YOUR BUSINESS',
    logo: pic004
  },
  {
    id: 5,
    link: 'https://nimbusweb.me/',
    title: 'Nimbus Platform',
    label: 'Online Collaboaration Tool for Business',
    logo: pic005
  }
]

const Partners = () => {

  const Logos = () => {
    var settings = {
      autoplay: true,
      dots: false,
      arrows: false,
      infinite: true,
      pauseOnHover: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
    return (
      <section id='partners' className='mx-5'>
        <Slider {...settings}>
          {data.map(e => (
            <a href={e.link} aria-label={e.label} target='blank' key={e.id}>
              <img
                width={200}
                height={120}
                src={e.logo}
                alt={e.title}
              />
            </a>
          ))}
        </Slider>
      </section>
    );
  }

  return (
    <section id='partners'>
      <div className="s-100"></div>
      <Logos />
      <div className="s-100"></div>
    </section>
  )
}

export {Partners}
