import React from 'react'
import { NavPages } from '../components/NavPages'
import { FooterPages } from '../components/FooterPages'

const Privacy = () => {
  return (
    <>
      <NavPages />
      <section>
        <div className="s-100"></div>
        <div className="s-40"></div>
        <div className="m-40"></div>
        <div className="m-20"></div>
        <div className="m-40"></div>
        <div className="m-20"></div>
        <div className="m-40"></div>
        <div className="container">
          <div className="mx-5 mob-table">
            <table className="c34 privacy-table">
              <tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1">
                  <p className="c29"><span className="c1">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</span></p></td>
                  <td className="c7 px-3" colspan="1" rowspan="1">
                  <p className="c38 c40"><span className="c2">PRIVACY POLICY</span></p>
                </td>
              </tr>
              <tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c1">Останнє оновлення: 18 січня 2023 р.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c17"><span className="c1">Last updated January 18, 2023</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span>Це повідомлення про конфіденційність для Благодійної Організації «Благодійний &nbsp;Фонд IT Паросток»</span><span className="c2">&nbsp;</span><span>(</span><span className="c5"><a className="c15" href="https://www.google.com/url?q=https://it-sprout.org.ua/&amp;sa=D&amp;source=editors&amp;ust=1674814257871103&amp;usg=AOvVaw0SXBUBxNrNu9XH3I4H2oif">https://it-sprout.org.ua/</a></span><span>) («</span><span className="c2">Компанія</span><span>», «</span><span className="c2">ми</span><span>», «</span><span className="c2">нас</span><span>» або «</span><span className="c2">наш</span><span>») описує, як і чому ми можемо збирати , зберігати, використовувати та/або надавати ("</span><span className="c2">обробляти</span><span>") вашу інформацію, коли ви користуєтеся нашими службами ("</span><span className="c2">Сервіси</span><span>"), наприклад, коли ви:</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span>This privacy policy for &nbsp;</span><span>Charitable Organization «Charitable Foundation &nbsp;IT Sprout» </span><span>(</span><span><a className="c15" href="https://www.google.com/url?q=https://it-sprout.org.ua/&amp;sa=D&amp;source=editors&amp;ust=1674814257872000&amp;usg=AOvVaw2kEH2_WufxCNST0hq5y-iJ">https://it-sprout.org.ua/</a></span><span>) ("</span><span className="c2">Company</span><span>," "</span><span className="c2">we</span><span>," "</span><span className="c2">us</span><span>," or "</span><span className="c2">our</span><span>"), describes how and why we might collect, store, use, and/or share ("</span><span className="c2">process</span><span>") your information when you use our services ("</span><span className="c2">Services</span><span className="c3">"), such as when you:</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_abbrqtpfalv-0 start"><li className="c8 c13 li-bullet-0"><span>відвідуєте наш веб-сайт за адресою </span><span className="c5"><a className="c15" href="https://www.google.com/url?q=https://it-sprout.org.ua/&amp;sa=D&amp;source=editors&amp;ust=1674814257872822&amp;usg=AOvVaw0guTRKY0iASCqJ414BUrEE">https://it-sprout.org.ua/</a></span><span className="c3">&nbsp;або будь-який наш веб-сайт, який посилається на це повідомлення про конфіденційність</span></li><li className="c8 c13 li-bullet-0"><span>Взаємодіяти з нами іншими пов’язаними способами, включно з будь-якими продажами, маркетингом або подіями</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_2uildhs3da9w-0 start"><li className="c8 c13 li-bullet-0"><span className="c10">Visit our website at </span><span>&nbsp;</span><span className="c5"><a className="c15" href="https://www.google.com/url?q=https://it-sprout.org.ua/&amp;sa=D&amp;source=editors&amp;ust=1674814257873906&amp;usg=AOvVaw0PHdCkIUBcUWVuvqvHgj15">https://it-sprout.org.ua/</a></span><span className="c16 c10">, or any website of ours that links to this privacy notice</span></li><li className="c8 c13 li-bullet-0"><span className="c16 c10">Engage with us in other related ways, including any sales, marketing, or events</span></li></ul><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Маєте запитання чи сумніви?</span><span>Ознайомлення з цим повідомленням про конфіденційність допоможе вам зрозуміти ваші права та вибір щодо конфіденційності. Якщо ви не згодні з нашою політикою, будь ласка, не користуйтеся нашими Послугами. Якщо у вас все ще є запитання чи сумніви, зв’яжіться з нами за &nbsp;адресою </span><span className="c5"><a className="c15" href="mailto:support@it-sprout.org.ua">support@it-sprout.org.ua</a></span><span>&nbsp;</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">Questions or concerns? </span><span className="c10">Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at</span><span className="c10">&nbsp;you may email us at </span><span className="c5 c10"><a className="c15" href="mailto:support@it-sprout.org.ua">support@it-sprout.org.ua</a></span><span className="c10">&nbsp;</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c1">РЕЗЮМЕ КЛЮЧОВИХ ПУНКТІВ</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">SUMMARY OF KEY POINTS</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c2">У цьому короткому викладі наведено ключові моменти з нашої політики конфіденційності.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c10 c2 c32">This summary provides key points from our privacy policy.</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Яку особисту інформацію ми обробляємо?</span><span>&nbsp;Коли ви відвідуєте, використовуєте або переміщуєтеся в наших Послугах, ми можемо обробляти особисту інформацію залежно від того, як ви взаємодієте з нашими Послугами, вибору, який ви робите, а також продуктів і функцій, які ви використовуєте.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">What personal information do we process?</span><span className="c16 c10">&nbsp;When you visit, use, or navigate our Services, we may process personal information depending on how you interact with our Services, the choices you make, and the products and features you use. </span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Чи обробляємо ми конфіденційну особисту інформацію?</span><span>&nbsp;Ми не обробляємо конфіденційну особисту інформацію.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">Do we process any sensitive personal information?</span><span className="c16 c10">&nbsp;We do not process sensitive personal information.</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Чи отримуємо ми будь-яку інформацію від третіх сторін?</span><span>&nbsp;Ми не отримуємо жодної інформації від третіх осіб.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">Do we receive any information from third parties?</span><span className="c16 c10">&nbsp;We do not receive any information from third parties.</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Як ми обробляємо вашу інформацію?</span><span>&nbsp;Ми обробляємо вашу інформацію, щоб надавати, покращувати та адмініструвати наші Послуги, спілкуватися з вами, для безпеки та запобігання шахрайству, а також для дотримання законодавства. Ми також можемо обробляти вашу інформацію для інших цілей за вашою згодою. Ми обробляємо вашу інформацію лише тоді, коли у нас є вагома законна підстава для цього. </span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">How do we process your information?</span><span className="c16 c10">&nbsp;We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">У яких ситуаціях і яким сторонам ми надаємо особисту інформацію?</span><span>&nbsp;Ми можемо ділитися інформацією в певних ситуаціях і з певними категоріями третіх сторін. </span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">In what situations and with which types of parties do we share personal information?</span><span className="c10">&nbsp;We may share information in specific situations and with specific categories of third parties. </span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Як ми забезпечуємо безпеку вашої інформації?</span><span>&nbsp;У нас є організаційні та технічні процеси та процедури для захисту вашої особистої інформації. Однак жодна електронна передача через Інтернет або технологія зберігання інформації не може бути гарантовано на 100% безпечною, тому ми не можемо обіцяти або гарантувати, що хакери, кіберзлочинці чи інші неавторизовані треті сторони не зможуть порушити нашу безпеку та неналежним чином збирати, отримувати доступ, викрасти чи змінити вашу інформацію.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">How do we keep your information safe?</span><span className="c10">&nbsp;We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. </span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Які ваші права?</span><span>&nbsp;Залежно від того, де ви географічно розташовані, відповідний закон про конфіденційність може означати, що ви маєте певні права щодо своєї особистої інформації. </span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">What are your rights?</span><span className="c10">&nbsp;Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. </span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Як ви реалізуєте свої права?</span><span>&nbsp;Найпростіший спосіб скористатися своїми правами – зв’язатися з нами електронною поштою. Ми розглянемо будь-який запит і відповімо на нього відповідно до чинного законодавства про захист даних.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">How do you exercise your rights?</span><span className="c10">&nbsp;The easiest way to exercise your rights is by contacting us via email. We will consider and act upon any request in accordance with applicable data protection laws.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span>Хочете дізнатися більше про те, що ми робимо з інформацією, яку збираємо? Перегляньте інформацію нижче.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">Want to learn more about what we do with any information we collect?</span><span className="c10">Check out the information below.</span></p></td></tr><tr className="c4">
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c1">ЗМІСТ</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">TABLE OF CONTENTS</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec1" className="c8"><span>1. ЯКУ ІНФОРМАЦІЮ МИ ЗБИРАЄМО?</span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec1" className="c8"><span className="c10">1. WHAT INFORMATION DO WE COLLECT?</span></a></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec2" className="c8"><span>2. ЯК МИ ОБРОБЛЯЄМО ВАШУ ІНФОРМАЦІЮ?</span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec2" className="c8"><span className="c10">2. HOW DO WE PROCESS YOUR INFORMATION?</span></a></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec3" className="c8"><span>3. ЯКИМИ ПРАВОВИМИ ПІДСТАВАМИ МИ КЕРУЄМОСЯ ДЛЯ ОБРОБКИ ВАШОЇ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ?</span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec3" className="c8"><span className="c10">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</span></a></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec4" className="c8"><span>4. КОЛИ І З КИМ МИ ДІЛИМОСЯ ВАШОЮ ОСОБИСТОЮ ІНФОРМАЦІЄЮ?</span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec4" className="c8"><span className="c10">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></a></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec5" className="c8"><span>5. ЧИ ВИКОРИСТОВУЄМО МИ ФАЙЛИ COOKIE ТА ІНШІ ТЕХНОЛОГІЇ ВІДСТЕЖЕННЯ? </span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec5" className="c8"><span className="c10">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? &nbsp;</span></a></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec6" className="c8"><span>6. ЯК ДОВГО МИ ЗБЕРІГАЄМО ВАШУ ІНФОРМАЦІЮ?</span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec6" className="c8"><span className="c10">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec7" className="c8"><span>7. ЯК МИ ЗАБЕЗПЕЧУЄМО БЕЗПЕКУ ВАШОЇ ІНФОРМАЦІЇ?</span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec7" className="c8"><span className="c10">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec8" className="c8"><span>8. ЧИ МИ ЗБИРАЄМО ІНФОРМАЦІЮ ВІД НЕПОВНОЛІТНІХ?</span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec8" className="c8"><span className="c10">8. DO WE COLLECT INFORMATION FROM MINORS?</span></a></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec9" className="c8"><span>9. ЯКІ ВАШІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec9" className="c8"><span className="c10">9. WHAT ARE YOUR PRIVACY RIGHTS?</span></a></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec10" className="c8"><span>10. ЕЛЕМЕНТИ КЕРУВАННЯ ФУНКЦІЯМИ НЕ ВІДСТЕЖУВАТИ</span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec10" className="c8"><span className="c10">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec11" className="c8"><span>11. ЧИ МАЮТЬ ЖИТЕЛІ КАЛІФОРНІЇ СПЕЦІАЛЬНІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec11" className="c8"><span className="c10">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec12" className="c8"><span>12. ЧИ МИ РОБИМО ОНОВЛЕННЯ ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?</span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec12" className="c8"><span className="c10">12. DO WE MAKE UPDATES TO THIS PRIVACY POLICY?</span></a></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec13" className="c8"><span>13. ЯК ВИ МОЖЕТЕ ЗВ'ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?</span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec13" className="c8"><span className="c10">13. HOW CAN YOU CONTACT US ABOUT THIS PRIVACY POLICY?</span></a></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec14" className="c8"><span>14. ЯК ВИ МОЖЕТЕ ПЕРЕГЛЯНУТИ, ОНОВИТИ АБО ВИДАЛИТИ ДАНІ, ЯКІ МИ ЗБИРАЄМО ВІД ВАС?</span></a></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><a href="#privacy_sec14" className="c8"><span>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></a></td></tr><tr className="c4" id="privacy_sec1"><td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c2">1. ЯКУ ІНФОРМАЦІЮ МИ ЗБИРАЄМО?</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">1. WHAT INFORMATION DO WE COLLECT?</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Особиста інформація, яку ви нам розкриваєте</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">Personal information you disclose to us</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c2">Коротко: </span><span className="c11">ми збираємо особисту інформацію, яку ви нам надаєте.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c10 c2">In Short: </span><span className="c11 c10">We collect personal information that you provide to us.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми збираємо особисту інформацію, яку ви добровільно надаєте нам, коли реєструєтеся на нашому сайті, виражаєте зацікавленість в отриманні інформації про нас або наші продукти та Послуги, коли ви заповнюєте форму на &nbsp;нашому &nbsp;сайті або іншим чином, коли звертаєтеся до нас.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c14 c38"><span className="c3">We collect personal information that you voluntarily provide to us when you register on our site, express interest in receiving information about us or our products and Services, when you fill out a form on our site, or otherwise contact us.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Особиста інформація, надана вами.</span><span className="c3">&nbsp;Особиста інформація, яку ми збираємо, залежить від контексту вашої взаємодії з нами та Сервісами, вибору, який ви робите, а також продуктів і функцій, які ви використовуєте. Особиста інформація, яку ми збираємо, може включати наступне:</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">Personal Information Provided by You.</span><span className="c10">&nbsp;The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</span></p></td></tr><tr className="c33"><td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_9rk60m9h59n0-0 start"><li className="c0 li-bullet-0"><span className="c3">ПІБ,</span></li></ul><ul className="c6 lst-kix_h6680u8qfjum-0 start"><li className="c0 li-bullet-0"><span className="c3">номери телефонів</span></li></ul><ul className="c6 lst-kix_5d9eby7kfclh-0 start"><li className="c0 li-bullet-0"><span className="c3">адреса електронної пошти,</span></li></ul><ul className="c6 lst-kix_226zqd75mopx-0 start"><li className="c0 li-bullet-0"><span className="c3">поштова адреса</span></li></ul><ul className="c6 lst-kix_kubz4wivm0fy-0 start"><li className="c0 li-bullet-0"><span className="c3">РНОКПП</span></li></ul><ul className="c6 lst-kix_9yqduyn48xc-0 start"><li className="c0 li-bullet-0"><span className="c3">посилання на сторінку Linkedin</span></li></ul><ul className="c6 lst-kix_3t89l3ia54eb-0 start"><li className="c0 li-bullet-0"><span className="c3">фотографія з вашим зображенням</span></li></ul><ul className="c6 lst-kix_8vq942nknyyx-0 start"><li className="c0 li-bullet-0"><span className="c3">відео з вашим зображенням</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_hbb909yf20pu-0 start"><li className="c0 li-bullet-0"><span className="c16 c10">names</span></li><li className="c0 li-bullet-0"><span className="c16 c10">phone numbers</span></li><li className="c0 li-bullet-0"><span className="c16 c10">email addresses</span></li><li className="c0 li-bullet-0"><span className="c16 c10">mailing addresses</span></li><li className="c0 li-bullet-0"><span className="c16 c10">identification code</span></li><li className="c0 li-bullet-0"><span className="c10">link to the </span><span className="c3">Linkedin page</span></li><li className="c0 li-bullet-0"><span className="c3">a photo with your image</span></li><li className="c0 li-bullet-0"><span>a video with your image</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Конфіденційна інформація.</span><span className="c3">&nbsp;Ми не обробляємо конфіденційну інформацію.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">Sensitive Information.</span><span className="c10">&nbsp;We do not process sensitive information.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Уся особиста інформація, яку ви надаєте нам, має бути правдивою, повною та точною, і ви повинні повідомляти нас про будь-які зміни такої особистої інформації.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Інформація, що збирається автоматично</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2 c31">Information automatically collected</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c2">Коротко: </span><span className="c11">деяка інформація, наприклад ваша адреса Інтернет-протоколу (IP) і/або характеристики браузера та пристрою, збирається автоматично, коли ви відвідуєте наші Сервіси.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c10 c2">In Short: </span><span className="c11 c10">Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми автоматично збираємо певну інформацію, коли ви відвідуєте, використовуєте або переміщуєтеся Сервісами. Ця інформація не розкриває вашу особистість (наприклад, ваше ім’я чи контактну інформацію), але може включати інформацію про пристрій і використання, як-от вашу IP-адресу, характеристики веб-переглядача та пристрою, операційну систему, мовні налаштування, URL-адреси переходу, назву пристрою, країну, місцезнаходження, інформацію про те, як і коли ви використовуєте наші Послуги, а також іншу технічну інформацію. Ця інформація в першу чергу потрібна для забезпечення безпеки та роботи наших Сервісів, а також для внутрішньої аналітики та звітності.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Як і багато компаній, ми також збираємо інформацію за допомогою файлів cookie та подібних технологій.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">Like many businesses, we also collect information through cookies and similar technologies.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Інформація, яку ми збираємо, включає:</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">The information we collect includes:</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_yo0xte6dz8u7-0 start"><li className="c0 li-bullet-0"><span className="c11">дані журналу та використання.</span><span className="c3">&nbsp;Дані журналу та використання — це інформація, пов’язана з послугами, діагностикою, використанням і продуктивністю, яку наші сервери автоматично збирають, коли ви отримуєте доступ до наших Послуг або використовуєте їх, і яку ми записуємо у файли журналів. Залежно від того, як ви взаємодієте з нами, дані журналу можуть включати вашу IP-адресу, інформацію про пристрій, тип браузера, а також, наприклад, позначки дати/часу, пов’язані з вашим використанням, сторінки та файли, які ви переглядаєте, пошуки та інші дії, які ви виконуєте, наприклад, які функції ви використовуєте, інформацію про події пристрою (такі як системна активність, звіти про помилки (іноді їх називають «аварійними дампами») та налаштування апаратного забезпечення).</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c18 c25"><span className="c16 c10"></span></p><ul className="c6 lst-kix_5l2q5sb5k938-0 start"><li className="c0 li-bullet-0"><span className="c11 c10">Log and Usage Data.</span><span className="c16 c10">&nbsp;Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</span></li></ul><p className="c9"><span className="c1"></span></p><p className="c18 c25"><span className="c16 c10"></span></p><p className="c18 c23"><span className="c16 c10"></span></p></td></tr><tr className="c4" id="privacy_sec2"><td className="c7 flex flex-col" colspan="1" rowspan="1"><p className="c8"><span className="c2">2. ЯК МИ ОБРОБЛЯЄМО ВАШУ ІНФОРМАЦІЮ?</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">2. HOW DO WE PROCESS YOUR INFORMATION?</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c2">Коротко: </span><span className="c11">ми обробляємо вашу інформацію, щоб надавати, покращувати та адмініструвати наші Сервіси, спілкуватися з вами, для безпеки та запобігання шахрайству, а також для дотримання законодавства. Ми також можемо обробляти вашу інформацію для інших цілей за вашою згодою.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c10 c2">In Short: </span><span className="c21 c11 c10">We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Ми обробляємо вашу особисту інформацію з різних причин, залежно від того, як ви взаємодієте з нашими Сервісами, зокрема:</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_nspq422yr55q-0 start"><li className="c0 li-bullet-0"><span className="c2">Для укладення угод с вами. </span><span className="c3">Ми можемо обробляти вашу інформацію для формування відповідних угод із вами та надіслання їх на вашу електронну адресу для підписання. </span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c14 c25"><span className="c3"></span></p><ul className="c6 lst-kix_qqnify3ukdh8-0 start"><li className="c14 c13 li-bullet-0"><span className="c2">To enter into agreements with you.</span><span>&nbsp;We may process your information to form relevant agreements with you and send them to your email address for signature.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_zas8yfbhgr5k-0 start"><li className="c0 li-bullet-0"><span className="c2">Для полегшення створення облікового запису та автентифікації та іншого керування обліковими записами користувачів. </span><span className="c3">Ми можемо обробляти вашу інформацію, щоб ви могли створити обліковий запис і ввійти в нього, а також підтримувати його в робочому стані.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_57thzpynlhs3-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">To facilitate account creation and authentication and otherwise manage user accounts. </span><span className="c16 c10">We may process your information so you can create and log in to your account, as well as keep your account in working order.</span></li></ul><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_57thzpynlhs3-0"><li className="c0 li-bullet-0"><span className="c2">Відповідати на запити користувачів/пропонувати підтримку користувачам. </span><span className="c3">Ми можемо обробляти вашу інформацію, щоб відповісти на ваші запити та вирішити будь-які потенційні проблеми, які можуть виникнути у вас із запитаною послугою.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_3f1n1i45d08e-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">To respond to user inquiries/offer support to users.</span><span className="c10">We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_3f1n1i45d08e-0"><li className="c0 li-bullet-0"><span className="c2">Щоб надіслати вам адміністративну інформацію. </span><span className="c3">Ми можемо обробляти вашу інформацію, щоб надсилати вам докладну інформацію про наші продукти та послуги, зміни наших умов і політики та іншу подібну інформацію.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_9bewfxrqvowv-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">To send administrative information to you. </span><span className="c10">We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_9bewfxrqvowv-0"><li className="c0 li-bullet-0"><span className="c2">Щоб запитати відгук.</span><span className="c3">Ми можемо обробляти вашу інформацію, коли це необхідно, щоб отримати відгук і зв’язатися з вами щодо використання вами наших Послуг.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_o7bvoeabj3yt-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">To request feedback. </span><span className="c10">We may process your information when necessary to request feedback and to contact you about your use of our Services.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_nxs5215frs6j-0 start"><li className="c0 li-bullet-0"><span className="c2">Щоб надсилати вам маркетингові та рекламні повідомлення. </span><span className="c3">Ми можемо обробляти особисту інформацію, яку ви надсилаєте нам, для наших маркетингових цілей, якщо це відповідає вашим маркетинговим уподобанням. Ви можете будь-коли відмовитися від наших маркетингових електронних листів. Для отримання додаткової інформації див. «ЯКІ ВАШІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?» нижче).</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_u70danlcsd9l-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">To send you marketing and promotional communications.</span><span className="c16 c10">We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below).</span></li></ul><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_u70danlcsd9l-0"><li className="c0 li-bullet-0"><span className="c2">Для доставки вам цільової реклами.</span><span className="c3">&nbsp;Ми можемо обробляти вашу інформацію для розробки та відображення персоналізованого вмісту та реклами відповідно до ваших інтересів, місцезнаходження тощо.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_okmnt4uhbe44-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">To deliver targeted advertising to you.</span><span className="c10">&nbsp;We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_rni6mc53hfyo-0 start"><li className="c0 li-bullet-0"><span className="c2">Щоб захистити наші Сервіси.</span><span className="c3">&nbsp;Ми можемо обробляти вашу інформацію в рамках наших зусиль, щоб забезпечити безпеку та безпеку наших Сервісів, включаючи моніторинг та запобігання шахрайству.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_ypcdd5an0ov6-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">To protect our Services.</span><span className="c10">&nbsp;We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_ypcdd5an0ov6-0"><li className="c0 li-bullet-0"><span className="c2">Щоб визначити тенденції використання.</span><span className="c3">&nbsp;Ми можемо обробляти інформацію про те, як ви використовуєте наші Сервіси, щоб краще зрозуміти, як вони використовуються, щоб ми могли їх покращити.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_g6y9lbbh5uyb-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">To identify usage trends.</span><span className="c10">&nbsp;We may process information about how you use our Services to better understand how they are being used so we can improve them.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_g6y9lbbh5uyb-0"><li className="c0 li-bullet-0"><span className="c2">Щоб визначити ефективність наших маркетингових і рекламних кампаній.</span><span className="c3">&nbsp;Ми можемо обробляти вашу інформацію, щоб краще зрозуміти, як проводити маркетингові та рекламні кампанії, які є найбільш релевантними для вас.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_h0oqihqrwhoy-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">To determine the effectiveness of our marketing and promotional campaigns.</span><span className="c10">&nbsp;We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_h0oqihqrwhoy-0"><li className="c0 li-bullet-0"><span className="c2">Для збереження або захисту життєво важливих інтересів особи.</span><span className="c3">&nbsp;Ми можемо обробляти вашу інформацію, коли це необхідно для збереження або захисту життєво важливих інтересів особи, наприклад, щоб запобігти завданню шкоди</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_cy14hbez4uae-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">To save or protect an individual's vital interest.</span><span className="c10">&nbsp;We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_cy14hbez4uae-0"><li className="c0 li-bullet-0"><span className="c2">Для покращення та популяризації наших Сервісів.</span><span>Ми можемо обробляти вашу інформацію шляхом розміщення фотографій та відео із вашим зображенням на наших веб-сайтах (сервісах), та веб- сайтах наших партнерів. При цьому ми можемо покращувати якість наданих вами фото та відео у будь-якій спосіб без спотворення вашого зображення (це може бути кроп,</span><span className="c19">&nbsp;заміна фону, яркості, тощо</span><span className="c3">).</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c14 c25"><span className="c3"></span></p><ul className="c6 lst-kix_nd1negtgw5ha-0 start"><li className="c14 c13 li-bullet-0"><span className="c2">To improve and popularize our Services.</span><span className="c3">&nbsp;We may process your information by placing photos and videos with your image on our websites (services) and the websites of our partners. At the same time, we can improve the quality of the photos and videos provided by you in any way without distorting your image (it can be cropping, replacing the background, brightness, etc.)</span></li></ul><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4" id="privacy_sec3"><td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c2">3. ЯКИМИ ПРАВОВИМИ ПІДСТАВАМИ МИ КЕРУЄМОСЯ ДЛЯ ОБРОБКИ ВАШОЇ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ?</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c2">Коротко: </span><span className="c11">ми обробляємо вашу особисту інформацію лише тоді, коли вважаємо це необхідним і маємо вагомі правові підстави (тобто правові підстави) для цього відповідно до чинного законодавства, наприклад, за вашою згодою, для дотримання законів, для надання вам послуг для прийняття або виконання наших договірних зобов’язань, захисту ваших прав або виконання наших законних ділових інтересів.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c10 c2">In Short: </span><span className="c11 c10 c21">We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c11 c2">Якщо ви перебуваєте в ЄС або Великобританії, цей розділ стосується вас.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c11 c10 c2">If you are located in the EU or UK, this section applies to you.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Загальний регламент захисту даних (GDPR) і GDPR Великобританії вимагають від нас пояснення чинних правових підстав, на які ми спираємося для обробки вашої особистої інформації. Таким чином, ми можемо покладатися на такі правові основи для обробки вашої особистої інформації:</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_z94dts7ye56z-0 start"><li className="c0 li-bullet-0"><span className="c2">Згода. </span><span className="c3">Ми можемо обробляти вашу інформацію, якщо ви дали нам дозвіл (тобто згоду) на використання вашої особистої інформації з певною метою. Ви можете відкликати свою згоду в будь-який час.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_g6ei6pfi92c0-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">Consent. </span><span className="c10">We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. </span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_jeuagx2mueb-0 start"><li className="c0 li-bullet-0"><span className="c2">Виконання контракту.</span><span className="c3">&nbsp;Ми можемо обробляти вашу особисту інформацію, якщо вважаємо це необхідним для виконання наших договірних зобов’язань перед вами, включаючи надання наших Послуг, або за вашим запитом до укладення з вами контракту.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_mzprgbudmfx1-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">Performance of a Contract.</span><span className="c10">&nbsp;We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_mzprgbudmfx1-0"><li className="c0 li-bullet-0"><span className="c2">Законні інтереси.</span><span className="c3">&nbsp;Ми можемо обробляти вашу інформацію, якщо вважаємо, що це обґрунтовано необхідно для досягнення наших законних ділових інтересів, і ці інтереси не переважають ваші інтереси та основні права та свободи. Наприклад, ми можемо обробляти вашу особисту інформацію для деяких із описаних цілей, щоб:</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_yaj8yl3zixoi-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">Legitimate Interests.</span><span className="c10">&nbsp;We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_yaj8yl3zixoi-0"><li className="c0 li-bullet-0"><span className="c3">надсилати користувачам інформацію про спеціальні пропозиції та знижки на наші продукти та послуги;</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_7tnxb5n6vuyg-0 start"><li className="c0 li-bullet-0"><span className="c10">Send users information about special offers and discounts on our products and services</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_7tnxb5n6vuyg-0"><li className="c0 li-bullet-0"><span className="c3">розробляти та відображати персоналізований і відповідний рекламний вміст для наших користувачів (в тому &nbsp;числі шляхом розміщення фотографій та відео із вашим зображенням на наших веб-сайтах (сервісах), та веб-сайтах наших партнерів);</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_nfr9e5iftkbk-0 start"><li className="c0 li-bullet-0"><span className="c10">Develop and display personalized and relevant advertising content for our users </span><span>(including by placing photos and videos with your image on our websites (services), and websites of our partners);</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_nfr9e5iftkbk-0"><li className="c0 li-bullet-0"><span className="c3">аналізувати, як використовуються наші Сервіси, щоб ми могли покращити їх, щоб залучати й утримувати користувачів</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_xt2v4ldv7w1h-0 start"><li className="c0 li-bullet-0"><span className="c10">Analyze how our Services are used so we can improve them to engage and retain users</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_xt2v4ldv7w1h-0"><li className="c0 li-bullet-0"><span className="c3">Підтримувати нашу маркетингову діяльність</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_iq5tmbsyxz79-0 start"><li className="c0 li-bullet-0"><span className="c10">Support our marketing activities</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_plvi2gw8xadg-0 start"><li className="c0 li-bullet-0"><span className="c3">Діагностувати проблеми та/або запобігати шахрайським діям</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_mgufq5oi8f7g-0 start"><li className="c0 li-bullet-0"><span className="c10">Diagnose problems and/or prevent fraudulent activities</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_ebzuu4em6ke4-0 start"><li className="c0 li-bullet-0"><span>Зрозуміти, як наші користувачі використовують наші продукти та послуги, щоб ми могли покращити взаємодію з ними.</span><span className="c2">&nbsp;</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_ed6pwlnknxeu-0 start"><li className="c0 li-bullet-0"><span className="c16 c10">Understand how our users use our products and services so we can improve user experience</span></li></ul><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c18"><span className="c2">Юридичні зобов’язання.</span><span className="c3">&nbsp;Ми можемо обробляти вашу інформацію, якщо вважаємо, що це необхідно для дотримання наших юридичних зобов’язань, наприклад, для співпраці з правоохоронними органами або регулюючими (контролюючими) органами, реалізації або захисту наших законних прав або розкриття вашої інформації як доказу в судовому процесі, в який ми залучені. </span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c18"><span className="c10 c2">Legal Obligations.</span><span className="c16 c10">&nbsp;We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulator agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_f0iru77xcl95-0 start"><li className="c0 li-bullet-0"><span className="c2">Життєво важливі інтереси.</span><span className="c3">&nbsp;Ми можемо обробляти вашу інформацію, якщо вважаємо це необхідним для захисту ваших життєво важливих інтересів або життєво важливих інтересів третьої сторони, наприклад, у ситуаціях, пов’язаних із потенційною загрозою безпеці будь-якої особи.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_lqgy7cyy9biz-0 start"><li className="c0 li-bullet-0"><span className="c10 c2">Vital Interests.</span><span className="c16 c10">&nbsp;We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</span></li></ul><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">З юридичної точки зору ми зазвичай є «контролером даних» відповідно до європейських законів про захист персональних даних, описаних у цієї політики &nbsp;конфіденційності, оскільки ми визначаємо засоби та/або цілі обробки даних, які ми виконуємо. &nbsp;Ця політика &nbsp;конфіденційності не стосується особистої інформації, яку ми обробляємо як «обробник даних» від імені наших клієнтів. У таких ситуаціях клієнт, якому ми надаємо послуги та з яким ми уклали угоду про обробку даних, є «контролером даних», відповідальним за вашу особисту інформацію, і ми лише обробляємо вашу інформацію від його імені відповідно до ваших інструкцій. Якщо ви хочете дізнатися більше про методи забезпечення конфіденційності наших клієнтів, ви повинні прочитати їхню політику конфіденційності та спрямувати їм будь-які запитання.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">In legal terms, we are generally the "data controller" under European data protection laws of the personal information described in this privacy notice, since we determine the means and/or purposes of the data processing we perform. This privacy policy does not apply to the personal information we process as a "data processor" on behalf of our customers. In those situations, the customer that we provide services to and with whom we have entered into a data processing agreement is the "data controller" responsible for your personal information, and we merely process your information on their behalf in accordance with your instructions. If you want to know more about our customers' privacy practices, you should read their privacy policies and direct any questions you have to them.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c11 c2">Якщо ви перебуваєте в Канаді, цей розділ стосується вас.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c10 c2 c11">If you are located in Canada, this section applies to you.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми можемо обробляти вашу інформацію, якщо ви дали нам окремий дозвіл (тобто явну згоду) на використання вашої особистої інформації для певної мети або в ситуаціях, коли ваш дозвіл можна припустити (тобто непряма згода). Ви можете відкликати свою згоду в будь-який час. </span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c16 c10">We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time. </span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">У деяких виняткових випадках відповідно до чинного законодавства нам може бути дозволено обробляти вашу інформацію без вашої згоди, зокрема, наприклад:</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_dhk0xu25pxbq-0 start"><li className="c0 li-bullet-0"><span className="c3">Якщо збір явно відповідає інтересам особи, а згоду не можна отримати своєчасно</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_vigikkere0ys-0 start"><li className="c0 li-bullet-0"><span className="c10">If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_ax9yfte0pute-0 start"><li className="c0 li-bullet-0"><span className="c3">Для розслідувань виявлення і запобігання шахрайства</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_6zbkgmwj0rkd-0 start"><li className="c0 li-bullet-0"><span className="c10">For investigations and fraud detection and prevention</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8 c25"><span className="c3"></span></p><ul className="c6 lst-kix_v7rkk39f0qwc-0 start"><li className="c8 c13 li-bullet-0"><span className="c3">Для ділових операцій за умови дотримання певних умов</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_nm2hbiex9ryi-0 start"><li className="c0 li-bullet-0"><span className="c10">For business transactions provided certain conditions are met</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_obhun6idbt6x-0 start"><li className="c0 li-bullet-0"><span className="c3">Якщо це міститься в показаннях свідка, а збір необхідний для оцінки, обробки або врегулювання страхової претензії</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_on5d3ghktmbx-0 start"><li className="c0 li-bullet-0"><span className="c10">If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_on5d3ghktmbx-0"><li className="c0 li-bullet-0"><span className="c3">Для ідентифікації поранених, хворих або померлих осіб і спілкування з найближчими родичами</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_uij6xyt73jbu-0 start"><li className="c0 li-bullet-0"><span className="c10">For identifying injured, ill, or deceased persons and communicating with next of kin</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_fa7sa8fkdiit-0 start"><li className="c0 li-bullet-0"><span className="c3">Якщо ми маємо обґрунтовані підстави вважати, що особа була, є чи може стати жертвою фінансових зловживань.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_7r9hr4o74imt-0 start"><li className="c0 li-bullet-0"><span className="c10">If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_gpxr0a58c16i-0 start"><li className="c0 li-bullet-0"><span className="c3">Якщо розумно очікувати, що збір і використання за згодою поставить під загрозу доступність або точність інформації, а збір є розумним для цілей, пов’язаних із розслідуванням порушення угоди або порушення законів Канади чи провінції.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_v7o5zsdkvy4-0 start"><li className="c0 li-bullet-0"><span className="c10">If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_v7o5zsdkvy4-0"><li className="c0 li-bullet-0"><span className="c3">Якщо розголошення необхідно для виконання за: повісткою в суд, ордером, ухвалою суду або правилами суду, що стосуються надання записів.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_2d18gtgbojgx-0 start"><li className="c0 li-bullet-0"><span className="c16 c10">If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</span></li></ul><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_t859swqqw1iy-0 start"><li className="c0 li-bullet-0"><span className="c3">якщо вони були створені особою під час її роботи, бізнесу чи професійної діяльності, і збір відповідає цілям, для яких інформація була створена</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_jt0fsvdj8181-0 start"><li className="c0 li-bullet-0"><span className="c10">If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_jt0fsvdj8181-0"><li className="c0 li-bullet-0"><span className="c3">Якщо збір призначений виключно для журналістських, мистецьких або літературних цілей</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_3j8zkcdbd1ld-0 start"><li className="c0 li-bullet-0"><span className="c10">If the collection is solely for journalistic, artistic, or literary purposes</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_3j8zkcdbd1ld-0"><li className="c0 li-bullet-0"><span className="c3">Якщо інформація є загальнодоступною та визначена правилами</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_fqf0dnmy4aoi-0 start"><li className="c0 li-bullet-0"><span className="c10">If the information is publicly available and is specified by the regulations</span></li></ul></td></tr><tr className="c4" id="privacy_sec4"><td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c1">4. КОЛИ І З КИМ МИ ДІЛИМОСЯ ВАШОЮ ОСОБИСТОЮ ІНФОРМАЦІЄЮ?</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c2">Коротко:</span><span className="c11">&nbsp;ми можемо ділитися інформацією в конкретних ситуаціях, описаних у цьому розділі, та/або з такими категоріями третіх сторін.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c10 c2">In Short:</span><span className="c11 c10">&nbsp;We may share information in specific situations described in this section and/or with the following categories of third parties.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Постачальники, консультанти, партнери та інші сторонні постачальники послуг.</span><span>&nbsp;Ми можемо надавати ваші дані стороннім постачальникам, постачальникам послуг, партнерам, підрядникам або агентам («</span><span className="c2">треті сторони</span><span className="c3">»), які надають послуги для нас (з якими ми маємо партнерські відносини) або від нашого імені та потребують доступу до такої інформації для виконання такої роботи. У нас є контракти (або будуть укладені в момент передачі даних) з нашими третіми сторонами, які призначені для захисту вашої особистої інформації. Це означає, що вони не можуть нічого робити з вашою особистою інформацією, якщо ми не доручимо їм це зробити. Вони також не повідомлятимуть вашу особисту інформацію жодній організації, крім нас. Вони також зобов’язуються захищати дані, які вони зберігають від нашого імені, і зберігати їх протягом періоду, який ми даємо. Категорії третіх осіб, яким ми можемо надавати особисту інформацію: інструменти маркетингу та продажів.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">Vendors, Consultants, and Other Third-Party Service Providers.</span><span className="c10">&nbsp;We may share your data with third-party vendors, service providers, partners contractors, or agents ("</span><span className="c10 c2">third parties</span><span className="c10">") who perform services for us (to whom we have partnership with) or on our behalf and require access to such information to do that work. We have contracts in place </span><span>(or will be concluded at the time of data transfer)</span><span className="c10">with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct. The categories of third parties we may share personal information with are as follows:</span><span className="c10">Sales &amp; Marketing Tools</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Нам також може знадобитися надати вашу особисту інформацію в таких ситуаціях: Ми можемо надавати або передавати вашу інформацію у зв’язку з або під час переговорів щодо будь-якого злиття, продажу активів компанії, фінансування або придбання всього або частини нашого бізнесу іншій компанії.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c16 c10">We also may need to share your personal information in the following situations: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4" id="privacy_sec5"><td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c2">5. ЧИ ВИКОРИСТОВУЄМО МИ ФАЙЛИ COOKIE ТА ІНШІ ТЕХНОЛОГІЇ ВІДСТЕЖЕННЯ?</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c2">Коротко:</span><span className="c11">&nbsp;ми можемо використовувати файли cookie та інші технології відстеження для збору та зберігання вашої інформації.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c10 c2">In Short:</span><span className="c11 c10">&nbsp;We may use cookies and other tracking technologies to collect and store your information.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми можемо використовувати файли cookie та подібні технології відстеження (наприклад, веб-маяки та пікселі) для доступу або зберігання інформації. </span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. </span></p></td></tr><tr className="c4" id="privacy_sec6"><td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c2">6. ЯК ДОВГО МИ ЗБЕРІГАЄМО ВАШУ ІНФОРМАЦІЮ?</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c2">Коротко: </span><span className="c11">ми зберігаємо вашу інформацію стільки, скільки необхідно для досягнення цілей, викладених у цієї політики конфіденційності, якщо інше не вимагається законом.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c10 c2">In Short: </span><span className="c11 c10">We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми зберігатимемо вашу особисту інформацію лише до тих пір, поки це необхідно для цілей, викладених у цієї політики конфіденційності, за винятком випадків, коли довший період зберігання вимагається або дозволяється законодавством (наприклад, вимогами податкового, бухгалтерського обліку чи іншими правовими вимогами). Жодна мета цього повідомлення не вимагає від нас зберігати вашу особисту інформацію довше тридцяти шести (36) місяців після припинення дії облікового запису користувача або розірвання відповідного договору або відзиву цієї згоди.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). </span><span className="c3">No purpose in this notice requires us to retain your personal information for longer than thirty-six (36) months after the user's account is terminated or the applicable agreement is terminated or this consent is revoked.</span></p><p className="c8 c25"><span className="c3"></span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Якщо у нас немає поточної законної комерційної потреби обробляти вашу особисту інформацію, ми або видалимо, або зробимо таку інформацію анонімною, або, якщо це неможливо (наприклад, оскільки ваша особиста інформація зберігалася в резервних архівах), ми безпечно будемо зберігати вашу особисту інформацію та ізолюємо її від будь-якої подальшої обробки, доки не стане можливим видалення.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></p></td></tr><tr className="c4" id="privacy_sec7"><td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c2">7. ЯК МИ ЗАБЕЗПЕЧУЄМО БЕЗПЕКУ ВАШОЇ ІНФОРМАЦІЇ?</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c2">Коротко: </span><span className="c11">ми прагнемо захистити вашу особисту інформацію за допомогою системи організаційних і технічних заходів безпеки.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c10 c2">In Short: </span><span className="c11 c10">We aim to protect your personal information through a system of organizational and technical security measures.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми запровадили відповідні та розумні технічні та організаційні заходи безпеки, спрямовані на захист будь-якої особистої інформації, яку ми обробляємо. Однак, незважаючи на наші заходи безпеки та зусилля щодо захисту вашої інформації, жодна електронна передача через Інтернет або технологія зберігання інформації не може бути гарантовано на 100% безпечною, тому ми не можемо обіцяти або гарантувати, що хакери, кіберзлочинці чи інші неавторизовані треті сторони не можуть порушити нашу безпеку та неналежним чином збирати, отримувати доступ, викрадати або змінювати вашу інформацію. Незважаючи на те, що ми зробимо все можливе, щоб захистити вашу особисту інформацію, передача особистої інформації до наших Сервісів (а &nbsp;також із них) здійснюється на ваш власний ризик. Ви повинні отримати доступ до Сервісів лише в безпечному середовищі.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c16 c10">We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4" id="privacy_sec8"><td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c2">8. ЧИ МИ ЗБИРАЄМО ІНФОРМАЦІЮ ВІД НЕПОВНОЛІТНІХ?</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">8. DO WE COLLECT INFORMATION FROM MINORS?</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c2">Коротко:</span><span className="c11">&nbsp;ми свідомо не збираємо дані від дітей віком до 18 років і не продаємо їм дані.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c10 c2">In Short:</span><span className="c11 c10">&nbsp;We do not knowingly collect data from or market to children under 18 years of age.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span>Ми свідомо не збираємо та не продаємо дані дітям віком до 18 років. Користуючись Сервісами, ви підтверджуєте, що вам виповнилося принаймні 18 років, або що ви є батьком або опікуном такої неповнолітньої особи та погоджуєтеся на використання Сервісів неповнолітньою особою. Якщо ми дізнаємося, що була зібрана особиста інформація користувачів віком до 18 років, ми деактивуємо обліковий запис і вживемо розумних заходів для негайного видалення таких даних із наших записів. Якщо вам стало відомо про будь-які дані, які ми могли зібрати від дітей віком до 18 років, зв’яжіться з нами за адресою </span><span className="c5"><a className="c15" href="mailto:support@it-sprout.org.ua">support@it-sprout.org.ua</a></span><span className="c3">&nbsp;</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at </span><span className="c5"><a className="c15" href="mailto:support@it-sprout.org.ua">support@it-sprout.org.ua</a></span><span>&nbsp;</span></p></td></tr><tr className="c4" id="privacy_sec9"><td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c2">9. ЯКІ ВАШІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">9. WHAT ARE YOUR PRIVACY RIGHTS?</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c2">Коротко:</span><span className="c11">&nbsp;у деяких регіонах, наприклад у Європейській економічній зоні (ЄЕЗ), Сполученому Королівстві (Великому Королівстві) і Канаді, ви маєте права, які надають вам більший доступ до вашої особистої інформації та контроль над нею. Ви можете переглянути, змінити або припинити дію свого облікового запису в будь-який час.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c10 c2">In Short:</span><span className="c21 c11 c10">&nbsp;In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">У деяких регіонах (наприклад, &nbsp;Велика Британія Канада) ви маєте певні права відповідно до чинного законодавства про захист даних. Це може включати право (i) вимагати доступу та отримання копії вашої особистої інформації, (ii) вимагати виправлення або видалення; (iii) забороняти обробку вашої особистої інформації, тощо. &nbsp;За певних обставин ви також можете мати право заперечити проти обробки вашої особистої інформації. Ви можете зробити такий запит, зв’язавшись з нами, використовуючи контактну інформацію, наведену в розділі «ЯК ВИ МОЖЕТЕ ЗВ’ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?» нижче.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми розглянемо будь-який запит і відповімо на нього відповідно до чинного законодавства про захист даних.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">We will consider and act upon any request in accordance with applicable data protection laws.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c2">Відкликання вашої згоди:</span><span className="c3">&nbsp;якщо ми покладаємося на вашу згоду на обробку вашої особистої інформації, яка може бути прямою та/або непрямою згодою залежно від чинного законодавства, ви маєте право відкликати свою згоду в будь-який час. Ви можете відкликати свою згоду в будь-який час, зв’язавшись з нами, використовуючи контактну інформацію, наведену в розділі «ЯК ВИ МОЖЕТЕ ЗВ’ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?» нижче.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c10 c2">Withdrawing your consent:</span><span className="c10">&nbsp;If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS PRIVACY POLICY?" below.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Однак зауважте, що це не вплине на законність обробки до її відкликання, а також, якщо це дозволяє чинне законодавство, це не вплине на обробку вашої особистої інформації, яка виконується на підставі законної обробки, крім згоди.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c2">Відмова від маркетингових і рекламних повідомлень: </span><span className="c3">Ви можете будь-коли скасувати підписку на наші маркетингові та рекламні повідомлення, відповівши «СТОП» або «СКАСУВАТИ ПІДПИСКУ» на SMS-повідомлення, які ми надсилаємо, або зв’язавшись з нами, використовуючи інформацію, наведену в розділі «ЯК ЧИ МОЖЕТЕ ВИ ЗВ'ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?" нижче. Після цього вас буде видалено з маркетингових списків. Однак ми все одно можемо спілкуватися з вами, наприклад, щоб надсилати вам пов’язані з послугами повідомлення, необхідні для адміністрування та використання вашого облікового запису, відповідати на запити щодо обслуговування або для інших немаркетингових цілей.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c10 c2">Opting out of marketing and promotional communications: </span><span className="c10">You can unsubscribe from our marketing and promotional communications at any time by replying "STOP" or "UNSUBSCRIBE" to the SMS messages that we send, or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Інформація про обліковий запис</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">Account Information</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Якщо ви в будь-який момент захочете переглянути чи змінити інформацію у своєму обліковому записі чи припинити дію вашого облікового запису, ви можете:</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">If you would at any time like to review or change the information in your account or terminate your account, you can:</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_l8ulv694d2eq-0 start"><li className="c0 li-bullet-0"><span className="c3">Зв’язатися з нами, використовуючи надану контактну інформацію.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_47e693rewf9o-0 start"><li className="c0 li-bullet-0"><span className="c3">Contact us using the contact information provided.</span></li></ul><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Після вашого запиту на припинення дії вашого облікового запису ми деактивуємо або видалимо ваш обліковий запис і інформацію з наших активних баз даних. Однак ми можемо зберігати певну інформацію в наших файлах, щоб запобігти шахрайству, усунути проблеми, допомогти в будь-яких розслідуваннях, забезпечити дотримання наших юридичних умов і/або дотримання відповідних юридичних вимог.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c2">Файли cookie та подібні технології:</span><span className="c3">&nbsp;більшість веб-браузерів налаштовано на прийом файлів cookie за замовчуванням. Якщо ви бажаєте, ви зазвичай можете налаштувати свій браузер на видалення та відхилення файлів cookie. Якщо ви вирішите видалити або відхилити файли cookie, це може вплинути на певні функції або служби наших Сервісів.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c10 c2">Cookies and similar technologies:</span><span className="c16 c10">&nbsp;Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. </span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span>Якщо у вас є запитання чи коментарі щодо ваших прав на конфіденційність, ви можете надіслати нам електронний лист на адресу </span><span className="c5"><a className="c15" href="mailto:support@it-sprout.org.ua">support@it-sprout.org.ua</a></span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c16 c10">If you have questions or comments about your privacy rights, you may email us at </span></p><p className="c8"><span className="c5"><a className="c15" href="mailto:support@it-sprout.org.ua">support@it-sprout.org.ua</a></span><span>&nbsp;</span></p></td></tr><tr className="c4" id="privacy_sec10"><td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c2">10. ЕЛЕМЕНТИ КЕРУВАННЯ ФУНКЦІЯМИ НЕ ВІДСТЕЖУВАТИ</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Більшість веб-браузерів і деякі мобільні операційні системи та програми для мобільних пристроїв включають функцію заборони відстеження («DNT» Do not Track). На даному етапі не розроблено єдиного технологічного стандарту для розпізнавання та реалізації сигналів DNT. Таким чином, наразі ми не реагуємо на сигнали браузера DNT або будь-який інший механізм, який автоматично повідомляє про ваш вибір не відстежувати вас в Інтернеті. Якщо буде прийнято стандарт онлайн-відстеження, якого ми повинні дотримуватися в майбутньому, ми повідомимо вас про цю практику в переглянутій версії цього повідомлення про конфіденційність.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></p></td></tr><tr className="c4" id="privacy_sec11"><td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c2">11. ЧИ МАЮТЬ ЖИТЕЛІ КАЛІФОРНІЇ СПЕЦІАЛЬНІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c2">Коротко: </span><span className="c11">так, якщо ви є резидентом Каліфорнії, вам надаються певні права щодо доступу до вашої особистої інформації.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c10 c2">In Short: </span><span className="c11 c10">Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Розділ 1798.83 Цивільного кодексу Каліфорнії, також відомий як закон «Shine The Light», дозволяє нашим користувачам, які є жителями Каліфорнії, запитувати та отримувати від нас раз на рік безкоштовно інформацію про категорії особистої інформації (за наявності), яку ми надаємо третім особам для цілей прямого маркетингу, а також імена й адреси всіх третіх сторін, яким ми надали особисту інформацію безпосередньо за попередній календарний рік. Якщо ви проживаєте в Каліфорнії та бажаєте подати такий запит, надішліть нам свій запит у письмовій формі, використовуючи контактну інформацію, наведену нижче.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Якщо вам менше 18 років, ви проживаєте в Каліфорнії та маєте обліковий запис у Сервісах, ви маєте право вимагати видалення небажаних даних, які ви публічно опублікуєте в Сервісах. Щоб подати запит на видалення таких даних, зв’яжіться з нами, використовуючи контактну інформацію, наведену нижче, і вкажіть адресу електронної пошти, пов’язану з вашим обліковим записом, і заяву про те, що ви проживаєте в Каліфорнії. Ми подбаємо про те, щоб дані не були публічно відображені в Сервісах, але майте на увазі, що дані не можуть бути повністю видалені з усіх наших систем (наприклад, резервні копії тощо).</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Повідомлення про конфіденційність CCPA</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">CCPA Privacy Notice</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Кодекс правил Каліфорнії визначає «резидента» як:</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">The California Code of Regulations defines a "resident" as:</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8 c24"><span className="c3">(1) кожну особу, яка перебуває в штаті Каліфорнія не з тимчасовою чи тимчасовою метою, і</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8 c24"><span className="c10">(1) every individual who is in the State of California for other than a temporary or transitory purpose and</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8 c24"><span className="c3">(2) кожну особу, яка проживає в штаті Каліфорнія, яка перебуває за межами штату Каліфорнія з тимчасовою або тимчасовою метою</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8 c24"><span className="c10">(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Усі інші особи визначаються як «нерезиденти».</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">All other individuals are defined as "non-residents."</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Якщо це визначення «резидента» стосується вас, ми повинні дотримуватися певних прав і зобов’язань щодо вашої особистої інформації.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми &nbsp;можемо збирати особисту інформацію, коли ви спілкуєтеся з нами особисто, онлайн, або телефоном чи електронною поштою в контексті:</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_pm3z956b1wl3-0 start"><li className="c0 li-bullet-0"><span className="c3">отримання допомоги через наші канали підтримки клієнтів;</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_eqstnvdk6ude-0 start"><li className="c0 li-bullet-0"><span className="c10">Receiving help through our customer support channels;</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_eqstnvdk6ude-0"><li className="c0 li-bullet-0"><span className="c3">Участь в опитуваннях або конкурсах клієнтів; та</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_skog5ko1fowe-0 start"><li className="c0 li-bullet-0"><span className="c10">Participation in customer surveys or contests; and</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_skog5ko1fowe-0"><li className="c0 li-bullet-0"><span className="c3">сприяння наданню наших Послуг і відповіді на ваші запити.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_pm3z956b1wl3-0"><li className="c0 li-bullet-0"><span className="c10">Facilitation in the delivery of our Services and to respond to your inquiries.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Як ми використовуємо та ділимося вашою особистою інформацією?</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2 c10">How do we use and share your personal information?</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми використовуємо та надаємо вашу особисту інформацію за допомогою:</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">We use and shares your personal information through:</span></p></td></tr><tr className="c22"><td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_jvh2q4vini3w-0 start"><li className="c0 li-bullet-0"><span className="c3">Цільових файлів cookie/Маркетингових файлів cookie</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_b0dsox6p4b1o-0 start"><li className="c0 li-bullet-0"><span className="c10">Targeting cookies/Marketing cookies</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_b0dsox6p4b1o-0"><li className="c28 c13 li-bullet-0"><span className="c3">Маяків/Пікселів/Тегів</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_lq9qhoczzw9d-0 start"><li className="c13 c28 li-bullet-0"><span className="c10">Beacons/Pixels/Tags</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Додаткову інформацію про наші способи збору та обміну даними можна знайти в цієї політики конфіденційності.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">More information about our data collection and sharing practices can be found in this privacy policy.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span>Ви можете зв’язатися з нами електронною поштою за адресою</span><span className="c5"><a className="c15" href="mailto:support@it-sprout.org.ua">support@it-sprout.org.ua</a></span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">You may contact us by email at </span><span className="c5"><a className="c15" href="mailto:support@it-sprout.org.ua">support@it-sprout.org.ua</a></span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Якщо ви використовуєте уповноваженого агента, щоб скористатися своїм правом відмовитися, ми можемо відхилити запит, якщо уповноважений агент не надасть доказ того, що він був належним чином уповноважений діяти від вашого імені.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми можемо розкривати вашу особисту інформацію нашим постачальникам послуг відповідно до письмового договору між нами та кожним постачальником послуг. Кожен постачальник послуг є комерційною організацією, яка обробляє інформацію від нашого імені.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми можемо використовувати вашу особисту інформацію для власних бізнес-цілей, наприклад для проведення внутрішніх досліджень для технологічного розвитку та демонстрації. Це не вважається «продажем» вашої особистої інформації.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Протягом останніх дванадцяти (12) місяців ми не продавали жодної особистої інформації третім сторонам з діловою чи комерційною метою. </span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">We have not sold any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. </span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c2">Ваші права щодо ваших особистих даних</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">Your rights with respect to your personal data</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5">Право вимагати видалення даних — Запит на видалення</span></p><p className="c8"><span className="c3">Ви можете вимагати видалення вашої особистої інформації. Якщо ви попросите нас видалити вашу особисту інформацію, ми задовольнимо ваш запит і видалимо вашу особисту інформацію, якщо інше не вимагається законом.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c10 c35">Right to request deletion of the data — Request to delete</span></p><p className="c8"><span className="c10">You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law. </span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5">Право на інформацію — Запит на інформацію</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c10">Right to be informed — Request to know</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Залежно від обставин ви маєте право знати:</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">Depending on the circumstances, you have a right to know:</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_i5ypuvue9xp0-0 start"><li className="c0 li-bullet-0"><span className="c3">чи збираємо ми та використовуємо вашу особисту інформацію;</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_n8720bvdvhl9-0 start"><li className="c0 li-bullet-0"><span className="c10">whether we collect and use your personal information;</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_n8720bvdvhl9-0"><li className="c0 li-bullet-0"><span className="c3">категорії особистої інформації, яку ми збираємо;</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_1orm71gyxx7p-0 start"><li className="c0 li-bullet-0"><span className="c10">the categories of personal information that we collect;</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_dwjb29xu92zm-0 start"><li className="c0 li-bullet-0"><span className="c3">цілі, для яких використовується зібрана персональна інформація;</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_umh1xsmzhtcj-0 start"><li className="c0 li-bullet-0"><span className="c10">the purposes for which the collected personal information is used;</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_umh1xsmzhtcj-0"><li className="c0 li-bullet-0"><span className="c3">чи продаємо ми вашу особисту інформацію третім сторонам;</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_ggi1zeae8z3o-0 start"><li className="c0 li-bullet-0"><span className="c10">whether we sell your personal information to third parties;</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_3041tectroi-0 start"><li className="c0 li-bullet-0"><span className="c3">категорії особистої інформації, яку ми продали або розкрили з комерційною метою;</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_vp4o8yk81f88-0 start"><li className="c0 li-bullet-0"><span className="c10">the categories of personal information that we sold or disclosed for a business purpose;</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_l94yyzd5r839-0 start"><li className="c0 li-bullet-0"><span className="c3">категорії третіх сторін, яким особиста інформація була продана або розкрита з комерційною метою; </span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_apiv49108caf-0 start"><li className="c0 li-bullet-0"><span className="c10">the categories of third parties to whom the personal information was sold or disclosed for a business purpose; </span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_apiv49108caf-0"><li className="c0 li-bullet-0"><span className="c3">також ділова або комерційна мета збору або продажу особистої інформації.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_tvjd1uxpifum-0 start"><li className="c0 li-bullet-0"><span className="c10">the business or commercial purpose for collecting or selling personal information</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Відповідно до чинного законодавства ми не зобов’язані надавати чи видаляти інформацію про споживача, яка була деідентифікована у відповідь на запит споживача, або повторно ідентифікувати індивідуальні дані для перевірки запиту споживача.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5">Право на недискримінацію щодо реалізації прав споживача на конфіденційність</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c10">Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми не будемо дискримінувати вас, якщо ви використовуєте свої права на конфіденційність.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">We will not discriminate against you if you exercise your privacy rights.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5">Процес перевірки</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c10">Verification process</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Отримавши ваш запит, нам потрібно буде підтвердити вашу особу, щоб визначити, що ви та сама особа, про яку ми маємо інформацію в нашій системі. Така перевірка вимагає наш запрос до вас надати інформацію, щоб ми могли порівняти її з інформацією, яку ви нам надали раніше. Наприклад, залежно від типу запиту, який ви подаєте, ми можемо попросити вас надати певну інформацію, щоб ми могли порівняти надану вами інформацію з інформацією, яку ми вже маємо, або ми можемо зв’язатися з вами за допомогою методу зв’язку (наприклад, телефону або електронної пошти), які ви надали нам раніше. Ми також можемо використовувати інші методи перевірки залежно від обставин.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми використовуватимемо особисту інформацію, надану у вашому запиті, лише для підтвердження вашої особи чи повноважень для подання запиту. Наскільки це можливо, ми будемо уникати запросу від вас додаткової інформації з метою перевірки. Проте, якщо ми не можемо підтвердити вашу особу на основі інформації, яку ми вже зберігаємо, ми можемо вимагати від вас надати додаткову інформацію з метою перевірки вашої особи та з метою безпеки чи запобігання шахрайству. Ми видалимо таку додатково надану інформацію, щойно завершимо вашу перевірку.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c16 c10">We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5">Інші права на конфіденційність</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c5 c10">Other privacy rights</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_m1ku1l468j4q-0 start"><li className="c0 li-bullet-0"><span className="c3">Ви можете заперечувати проти обробки вашої особистої інформації.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_k29siexa056z-0 start"><li className="c0 li-bullet-0"><span className="c10">You may object to the processing of your personal information.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_57vpcwc1826-0 start"><li className="c0 li-bullet-0"><span className="c3">Ви можете вимагати виправлення своїх персональних даних, якщо вони невірні або більше не актуальні, або просити обмежити обробку інформації.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_mz4jj1ji1br-0 start"><li className="c0 li-bullet-0"><span className="c10">You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_hcd0ni8v16s-0 start"><li className="c0 li-bullet-0"><span className="c3">Ви можете призначити уповноваженого агента для подання запиту відповідно до CCPA від вашого імені. Ми можемо відхилити запит від уповноваженого агента, який не надав доказів того, що він був належним чином уповноважений діяти від вашого імені відповідно до CCPA.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_f4h4kxjz5uma-0 start"><li className="c0 li-bullet-0"><span className="c10">You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_fodw5d99r513-0 start"><li className="c0 li-bullet-0"><span className="c3">Ви можете попросити відмовитися від майбутнього продажу вашої особистої інформації третім особам. Отримавши запит на відмову, ми задовольнимо запит якнайшвидше, але не пізніше ніж через п’ятнадцять (15) днів із дати подання запиту.</span></li></ul></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><ul className="c6 lst-kix_ytcvq0r53vtg-0 start"><li className="c0 li-bullet-0"><span className="c10">You may request to opt out from future selling of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.</span></li></ul></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span>Щоб скористатися цими правами, ви можете зв’язатися з нами електронною поштою за адресою</span><span className="c5"><a className="c15" href="mailto:support@it-sprout.org.ua">support@it-sprout.org.ua</a></span><span className="c3">. </span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">To exercise these rights, you can contact us by email at </span><span className="c5"><a className="c15" href="mailto:support@it-sprout.org.ua">support@it-sprout.org.ua</a></span><span className="c10">. </span></p></td></tr><tr className="c4" id="privacy_sec12"><td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c2">12. ЧИ МИ РОБИМО ОНОВЛЕННЯ ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">12. DO WE MAKE UPDATES TO THIS PRIVACY POLICY?</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c2">Коротко: </span><span className="c11">так, ми будемо оновлювати цю політику &nbsp;конфіденційності, якщо це необхідно, щоб дотримуватися відповідних законів.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c11 c10 c2">In Short: </span><span className="c11 c10">Yes, we will update this notice as necessary to stay compliant with relevant laws.</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c3">Ми можемо час від часу оновлювати цю політику &nbsp;конфіденційності. Оновлена ​​версія буде позначена оновленою датою «Переглянута», і оновлена ​​версія набуде чинності, щойно стане доступною. Якщо ми внесемо суттєві зміни в цю політику &nbsp;конфіденційності, ми можемо повідомити вас, розмістивши повідомлення про такі зміни на помітному місці, або безпосередньо надіславши вам повідомлення. Радимо вам частіше переглядати цю політику &nbsp;конфіденційності, щоб бути в курсі того, як ми захищаємо вашу інформацію.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c16 c10">We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4" id="privacy_sec13"><td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c2">13. ЯК ВИ МОЖЕТЕ ЗВ'ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ?</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c31 c10 c2">13. HOW CAN YOU CONTACT US ABOUT THIS PRIVACY POLICY?</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span>Якщо у вас є запитання чи коментарі щодо цієї політики &nbsp;конфіденційності, ви можете надіслати нам електронний лист на адресу </span><span className="c5"><a className="c15" href="mailto:admin@it-sprout.org.ua">admin@it-sprout.org.ua</a></span><span className="c3">&nbsp;або поштою на адресу:</span></p><p className="c8"><span className="c3">Благодійна Організація «Благодійний &nbsp;Фонд IT Паросток», ідентифікаційний &nbsp;код юридичної особи 44550531, Україна, 65007, Одеська область, місто Одеса, Приморський район, вулиця Старопортофранківська, 107 квартира 21.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">If you have questions or comments about this notice, you may email us at</span><span className="c5"><a className="c15" href="mailto:support@it-sprout.org.ua">support@it-sprout.org.ua</a></span><span className="c10 c16">&nbsp;or by post to: </span></p><p className="c8"><span>Charitable Organization «Charitable Foundation &nbsp;IT Sprout»,</span><span>Identification code of the legal entity 44550531; 65007, Odessa, Odessa region, Staroportofrankivska Street 107 apartment 21</span></p><p className="c8 c25"><span className="c16 c10"></span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4" id="privacy_sec14"><td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c2">14. ЯК ВИ МОЖЕТЕ ПЕРЕГЛЯНУТИ, ОНОВИТИ АБО ВИДАЛИТИ ДАНІ, ЯКІ МИ ЗБИРАЄМО ВІД ВАС?</span></p></td>
                <td className="c7 pt-10" colspan="1" rowspan="1"><p className="c8"><span className="c10 c2">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span>Ви маєте право вимагати доступу до особистої інформації, яку ми збираємо від вас, змінити цю інформацію або видалити її. Щоб подати запит на перегляд, оновлення або видалення вашої особистої інформації, ви можете надіслати нам електронний лист на адресу </span><span className="c5"><a className="c15" href="mailto:support@it-sprout.org.ua">support@it-sprout.org.ua</a></span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c8"><span className="c10">You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, you may email us at </span><span className="c5"><a className="c15" href="mailto:support@it-sprout.org.ua">support@it-sprout.org.ua</a></span><span className="c10">&nbsp;</span></p><p className="c9"><span className="c1"></span></p></td></tr><tr className="c4">
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c36"><span>Ця політика конфіденційності складена українською та англійською мовами. У разі виникнення суперечок в її тлумаченні, пов’язаних з перекладом, завжди застосовується українська версія.</span></p></td>
                <td className="c7 px-3" colspan="1" rowspan="1"><p className="c14"><span>This Privacy Policy is made in Ukrainian and English. In case of disputes in the interpretation of it related to the translation, the Ukrainian version is always used.</span></p></td></tr></table>

          </div>
        </div>
        <div className="s-100"></div>
        <div className="m-40"></div>
      </section>
      <FooterPages />
    </>
  )
}

export {Privacy}
