import React from "react"

const Help = () => {
  return (
    <>
    <section id="help">
      <div className="s-40"></div>
      <div className="s-100"></div>
      <h3 className="title-parts c-marine tc">Приєднуйтеся до нас</h3>
      <div className="s-20"></div>
      <p className="txt c-dark tc w-60 ma">Станьте частиною змін та підтримуйте людей з підвищеними потребами, ветеранів та їх сім'ї. Разом ми можемо допомогти багатьом!</p>
      <div className="s-100"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 bg-h1 m-show m-down-60"></div>
          <div className="col-lg-6 p-5 tl flex jcc aic">
            <div className="m-md-5">
              <h3 className="title-part c-marine">Ветерани війни</h3>
              <div className="s-10"></div>
              <p className="title-sub c-grey">Підтримайте ветеранів – героїв, які самовіданно захищали нашу країну.</p>
              <div className="s-10"></div>
              <p className="txt">Наша підтримка ветеранів війни - це шлях підтримати наших героїв, які віддали все заради нашої країни та безпеки. Ваша допомога допоможе їм в адаптації до цивільного життя та отриманні необхідних послуг.</p>
              <div className="s-10"></div>
              <a href="https://form.typeform.com/to/KnyTqmCK#business_type=media_agency&source_id=our-website&button_id=support" className="button" target='blank'>Підтримати</a>
            </div>
          </div>
          <div className="col-lg-6 bg-h1 m-none"></div>
        </div>
        <div className="row">
          <div className="col-lg-6 bg-h2 m-down-60 m-up-60 m-tr-60"></div>
          <div className="col-lg-6 p-5 tl flex jcc aic">
            <div className="m-md-5">
              <h3 className="title-part c-marine">Люди з підвищеними потребами</h3>
              <div className="s-10"></div>
              <p className="title-sub c-grey">Допоможіть людям з підвищеними потребами отримати рівні можливості.</p>
              <div className="s-10"></div>
              <p className="txt">Люди з підвищеними потребами заслуговують на рівні можливості та повагу. Ваша підтримка допоможе їм отримати доступ до освіти, робочих місць та соціальних послуг, необхідних для їхнього самостійного та щасливого життя.</p>
              <div className="s-10"></div>
              <a href="https://form.typeform.com/to/KnyTqmCK#business_type=media_agency&source_id=our-website&button_id=support" className="button" target='blank'>Підтримати</a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 bg-h3 m-show m-down-60 m-tr-60"></div>
          <div className="col-lg-6 p-5 tl flex jcc aic">
            <div className="m-md-5">
              <h3 className="title-part c-marine">Внутрішньо переміщені особи</h3>
              <div className="s-10"></div>
              <p className="title-sub c-grey">Підтримайте Внутрішньо переміщені особи у пошуках нового дому.</p>
              <div className="s-10"></div>
              <p className="txt">Внутрішньо переміщені особи потребують допомоги та підтримки у пошуках нового дому та нового початку. Ваша спонсорська підтримка допоможе їм в адаптації до нових умов та отриманні необхідних ресурсів для стабільного майбутнього.</p>
              <div className="s-10"></div>
              <a href="https://form.typeform.com/to/KnyTqmCK#business_type=media_agency&source_id=our-website&button_id=support" className="button" target='blank'>Підтримати</a>
            </div>
          </div>
          <div className="col-lg-6 bg-h3 m-none"></div>
        </div>
      </div>
    </section>
    <div className="s-100"></div>
    <div className="s-100"></div>
    </>
  )
}

export { Help }
