import React from "react"

const Intro = () => {
  return (
    <section id="top">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="s-20"></div>
            <h2 className="title-intro">Соціалізація через ІТ: допомога та підтримка соціально вразливим групам</h2>
            <div className="s-20"></div>
            <p className="c-white txt-intro">Ми допомагаємо людям з підвищеними потребами, ветеранам та їх сім'ям отримати IT навички та працювати з дому. Наша унікальна авторська методика дуальної освіти забезпечує плавне погруження в роботу реальної компанії без дискомфорту.</p>
            <div className="s-40"></div>
            <a href="https://form.typeform.com/to/KnyTqmCK#business_type=partnership&source_id=our-website&button_id=become_partner" className="button marine" target='blank'>Стати партнером</a>
            <a href="https://form.typeform.com/to/KnyTqmCK#business_type=sponsorship&source_id=our-website&button_id=become_sponsor" className="button theme mx-md-3" target='blank'>Стати спонсором</a>
            <div className="s-100"></div>
            <div className="s-60"></div>
          </div>
          <div className="col-lg-5"></div>
        </div>
      </div>
    </section>
  )
}

export { Intro }
