import React from 'react'

const Join = () => {
  return (
    <section id="join">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 bg-005"></div>
          <div className="col-lg-6 bg-white">
            <div className="s-60"></div>
            <div className="w-70 ma m-in-right">
              <h3 className="title c-marine">Як приєднатися?</h3>
              <div className="s-20"></div>
              <ul className="timeline-with-icons">
                <li className="timeline-item my-5">
                  <span className="timeline-icon">📋</span>
                  <p className="title-sub">Реєстрація та вибір напрямку</p>
                </li>
                <li className="timeline-item my-5">
                  <span className="timeline-icon">📝</span>
                  <p className="title-sub">Проходження тестового завдання</p>
                </li>
                <li className="timeline-item my-5">
                  <span className="timeline-icon">📅</span>
                  <p className="title-sub">Iнтерв'ю</p>
                </li>
              </ul>
              <div className="s-10"></div>
              <a href="https://form.typeform.com/to/KnyTqmCK#business_type=it_courses&source_id=our-website&button_id=register" className='button' target='blank'>Зареєструватися</a>
              <div className="s-60"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export {Join}
