import React, { useState } from 'react'
import logotype from '../logo.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Nav = () => {

  const [opened,setOpened] = useState(true)

  return (
    <header className='w-100'>
      <div className='container'>
        <header className="flex jcsb aic py-3">
          <h1 id="logo">
            <a href='/' aria-label="IT Sprout - Головна"><LazyLoadImage src={logotype} width={48} height={46} alt="IT Sprout" /></a>
          </h1>
          <div className={opened ? 'burger' : 'burger open'} onClick={() => setOpened(!opened)}>
            <span className='tt'></span>
            <span className='mm'></span>
            <span className='bb'></span>
          </div>
          <div className={opened ? 'menu active' : 'menu'}>
            <a href="#about" className='mx-3 px-1' onClick={() => setOpened(!opened)}>Про&nbsp;&nbsp;Нас</a>
            <a href="#career" className='mx-3 px-1' onClick={() => setOpened(!opened)}>Кар’єра</a>
            <a href="#partners" className='mx-3 px-1' onClick={() => setOpened(!opened)}>Партнери</a>
            <a href="#help" className='mx-3 px-1' onClick={() => setOpened(!opened)}>Спонсорам</a>
            <a href="#contact" className='mx-3 px-1' onClick={() => setOpened(!opened)}>Контакти</a>
          </div>
        </header>
      </div>
    </header>
  )
}

export {Nav}
