import React from "react"
import { HiPuzzlePiece, HiMegaphone, HiIdentification } from "react-icons/hi2"

const marine = '#00AACB'

const Сareer = () => {
  return (
    <>
    <div className="s-100"></div>
    <div className="s-100"></div>
    <section id="career">
      <div className="container-fluid">
        <div className="s-100"></div>
        <div className="s-100"></div>
        <div className="m-60"></div>
        <h3 className="title tc c-marine">Відкрийте свій потенціал у ІТ</h3>
        <div className="s-30"></div>
        <p className="w-60 m-100 ma tc c-white">Дізнайтеся про наші пропозиції для вашої кар'єри в ІТ. Ми пропонуємо можливості у креативній індустрії, рекламі, маркетингу та створенні контенту.</p>
        <div className="s-20"></div>
        <div className="row tc">
          <div className="col-lg-4 py-5">
            <HiPuzzlePiece size={64} color={marine} />
            <div className="s-20"></div>
            <h4 className="c-marine title-sub">Креативна індустрія та контент:</h4>
            <div className="s-20"></div>
            <p className="w-60 m-100 ma tc c-white h-20">Створюйте захоплюючий контент, візуалізуйте ідеї та реалізуйте креативні проекти.</p>
            <a href="https://form.typeform.com/to/KnyTqmCK#business_type=volunteer_organization&source_id=our-website&button_id=join" className="button" target='blank'>Приєднатися</a>
          </div>
          <div className="col-lg-4 py-5">
            <HiMegaphone size={64} color={marine} />
            <div className="s-20"></div>
            <h4 className="c-marine title-sub">Маркетинг та продажі:</h4>
            <div className="s-20"></div>
            <p className="w-60 m-100 ma tc c-white h-20">Вивчайте стратегії маркетингу, оптимізуйте продажі та просувайте бренди на ринку.</p>
            <a href="https://form.typeform.com/to/KnyTqmCK#business_type=volunteer_organization&source_id=our-website&button_id=join" className="button" target='blank'>Приєднатися</a>
          </div>
          <div className="col-lg-4 py-5">
            <HiIdentification size={64} color={marine} />
            <div className="s-20"></div>
            <h4 className="c-marine title-sub">Комунікації та PR:</h4>
            <div className="s-20"></div>
            <p className="w-60 m-100 ma tc c-white h-20">Створюйте ефективні комунікаційні стратегії, працюйте над репутацією та зв'язками з громадськістю.</p>
            <a href="https://form.typeform.com/to/KnyTqmCK#business_type=volunteer_organization&source_id=our-website&button_id=join" className="button" target='blank'>Приєднатися</a>
          </div>
        </div>
        <div className="s-80"></div>
        <div className="s-100"></div>
      </div>
    </section>
    <div className="s-100"></div>
    <div className="s-100"></div>
    </>
  )
}

export { Сareer }
