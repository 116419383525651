import React from 'react'
import { Nav } from '../components/Nav'
import { Intro } from './parts/Intro'
import { About } from './parts/About'
import { Сareer } from './parts/Сareer'
import { Join } from './parts/Join'
import { Help } from './parts/Help'
import { Sponsors } from './parts/Sponsors'
import { Footer } from '../components/Footer'
import {Partners} from './parts/Partners'

const Home = () => {
  return (
    <>
      <Nav />
      <Intro />
      <Partners />
      <About />
      <Сareer />
      <Join />
      <Help />
      <Sponsors />
      <Footer />
    </>
  )
}

export {Home}
