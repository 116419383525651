import React from 'react'

const About = () => {
  return (
    <section id="about">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 bg-white">
            <div className="s-100"></div><div className="s-100"></div>
            <h3 className="title w-80 ma c-marine">Допомога та підтримка спільноти</h3>
            <div className="s-40"></div>
            <p className='w-80 ma'>Ми надаємо технічну допомогу та інструменти для підтримки людей з підвищеними потребами, ветеранів, їх сім'ї та інших у зусиллях забезпечити їм рівні можливості в ІТ.</p>
            <div className="s-100"></div><div className="s-100"></div>
          </div>
          <div className="col-lg-6 bg-001"></div>
        </div>
        <div className="row">
          <div className="col-lg-6 bg-002"></div>
          <div className="col-lg-6 bg-white">
            <div className="s-100"></div><div className="s-100"></div>
            <h3 className="title w-80 ma c-marine">Партнерство для підтримки</h3>
            <div className="s-40"></div>
            <p className='w-80 ma'>Ми співпрацюємо з організаціями та громадськими ініціативами, щоб підтримувати людей з підвищеними потребами, ветеранів, їх сім'ї та інших. Разом ми можемо створити кращий світ.</p>
            <div className="s-100"></div><div className="s-100"></div>
          </div>
        </div>
        <div className="row m-up-60">
          <div className="col-lg-6">
            <div className="s-100 m-none"></div><div className="s-100 m-none"></div>
            <h3 className="title w-80 ma c-marine">Ваш шлях до ІТ</h3>
            <div className="s-40"></div>
            <p className='w-80 ma'>Ми пропонуємо готові рішення та цифрові послуги для людей з підвищеними потребами, ветеранів, їх сім'ї та інших. Наша команда готова поділитися знаннями та ресурсами для вашого розвитку.</p>
            <div className="s-100"></div><div className="s-100"></div>
          </div>
          <div className="col-lg-6 bg-003"></div>
        </div>
        <div className="row m-up-60">
          <div className="col-lg-6 bg-004"></div>
          <div className="col-lg-6 bg-white">
            <div className="s-100 m-none"></div><div className="s-100 m-none"></div>
            <h3 className="title w-80 ma c-marine">Разом до майбутнього без бар'єрів</h3>
            <div className="s-40"></div>
            <p className='w-80 ma'>Ми цінуємо вашу підтримку та пропонуємо нашу експертизу та допомогу. Разом ми можемо створити інклюзивне майбутнє для всіх.</p>
            <div className="s-100"></div><div className="s-100"></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export {About}
